<template>
	<div class="container">
		<Header  :flag="flag" />	
		<div class="banner">
			<div class="banner-content">
				<span class="banner-text">
				 关于蓝莓科技
				 </span>	
				 <br/>
				<span class="banner-subtitle-text">
				致力于创造更加美好的未来
				</span>	
			</div>
		</div>
		<div class="main">
			<div class="intro">
				<div class="title">
					<img src="../assets/logo.png" />
					<br/>
					<span>蓝莓科技</span>
				</div>
				<div class="content" v-html="data.content">
				</div>
			</div>
			<div class="aboutus"></div>
		</div>
		<div class="culture">
			<div class="culture-div">
				<div class="culture-content">
					<div class="content-img">
						<img src="../assets/img/ic_cp1.png" align="absbottom" /><span class="culture-title">企业的使命</span>
					</div>
					<div class="content-text">
						<span class="culture-subtitle">让天下没有难做的销售</span>
					</div>
				</div>
				<div class="culture-content">
					<div class="content-img">
						<img src="../assets/img/ic_cp2.png" align="absbottom" /><span class="culture-title">企业的价值观</span>
					</div>
					<div class="content-text">
						<span class="culture-subtitle">团结合作、创新进取、成就客户</span>
					</div>
				</div>
			</div>
		</div>
		<div class="development">
			<div class="title">
				公司发展史
			</div>
			<div class="time-line">
				<div class="time-content" v-for="(item,index) in courseList">
					<div class="time-content-l">
						<div class="l-div1" v-if="index%2>0">
							<div class="l-div1-l">
								<span class="time-text">{{item.title}}</span>
								<br/>
								<span class="content">{{item.intro}}</span>
							</div>
							<div class="l-div1-r"></div>
						</div>
					</div>
					<div class="time-content-r">
						<div class="r-div1" v-if="index%2===0">
							<div class="r-div1-l"></div>
							<div class="r-div1-r">
								<span class="time-text">{{item.title}}</span>
								<br/>
								<span class="content">{{item.intro}}</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<Footer />	
	</div>
</template>

<script>
	import Header from '@/components/Header.vue'
	import Footer from '@/components/Footer.vue'
	export default {
		metaInfo: {
			title: '蓝莓科技-用智能的销售、全国企业大数据和AI帮助企业全方位提升销售效率|关于蓝莓科技',
			meta: [
			  {
				name: 'keywords',
				content: ''
			  },
			  {
				name: 'description',
				content: '蓝莓科技是专业的智能销售SaaS服务提供商，致力于用大数据和AI技术帮助企业全面提升销售效率。'
			  }
			]
		},
		name: 'Company',
		components: {
			Header,
			Footer
		},	
		data(){
			return{
				flag:'us',
				data: [],
				courseList:[]
			}
		},
		created() {
		    this.getCourseList();	
			this.getIntro();
		},
		methods:{
			async getIntro(){
			   await this.$http.getCompanyIntro().then(res => {
					if(res.data!=null){
						this.data = res.data;
					}
				})
			},
			getCourseList(){
				this.$http.getCoursesList().then(res => {
					if(res.data!=null){
						this.courseList = res.data;
					}
				})
			}	
		}
	}	
</script>

<style scoped lang="less">
	.banner{
		 height: 360px;
		 background-image: url(https://file.lanmeihulian.com/SX_BANNER.png);
		 background-repeat: no-repeat;
		 background-size:100% 100%;
		 .banner-content{
			 width: 1200px;
			 margin: auto;
			 .banner-text{
				 display:inline-block;
				 margin-top: 100px;
				 text-align: center;
				 font-size: 60px;
				 font-weight: 600;
				 line-height: 100px;
				 color: white;
			 }
			 .banner-subtitle-text{
				 display:inline-block;
				 text-align: center;
				 font-size: 28px;
				 color: white;
			 }
		 }
	}
	.main{
		width: 1200px;
		margin: auto;
		.intro{
			height: 300px;
			margin-top: 60px;
			display: flex;
			display: -webkit-flex;
			flex-direction: row;
			.title{
				padding-top: 50px;
				padding-left: 50px;
				height: 200px;
				width: 400px;
				font-size: 34px;
				font-weight: 600;
				line-height: 50px;
				text-align: center;
				color: #217bfb;
				img{
					width: 90px;
					height: 90px;
				}
			}	
			.content{
				width: 750px;
				font-size: 18px;
				line-height: 30px;
			}	
		}
		.aboutus{
			height: 85px;
			width: 900px;
			margin: auto;
			background-image: url(../assets/img/cp_ABOUTUS.png);
			background-repeat: no-repeat;
		}
		
	}
	.culture{
		height: 240px;
		background-color: #f6f7fa;
		.culture-div{
			width: 1200px;
			margin: auto;
			height: 240px;
			display: flex;
			display: -webkit-flex;
			flex-direction: row;
			justify-content: space-around;
			.culture-content{
				width: 400px;
				height: 240px;
				vertical-align: middle;
				.content-img{
					margin-top: 40px;
					.culture-title{
						font-size: 30px;
						line-height: 60px;
						font-weight: 600;
						padding-left: 24px;
						color: #217bfb;
					}
				}
				.content-text{
					margin-top: 24px;
					margin-left: 100px;
					.culture-subtitle{
						font-size: 26px;
					}
				}
			
			}
		}
	}
	.development{
		width: 1200px;
		min-height: 600px;
		height: auto !important;
		margin: auto;
		.title{
			background-image: url(../assets/img/FONT_DEVELOPMENTHISTORY.png);
			background-repeat: no-repeat;
			width: 473px;
			height: 110px;
			margin-top: 56px;
			font-size: 38px;
			font-weight: 600;
			line-height: 80px;
			padding-top: 10px;
		}	
		.time-line{
			margin-top: 69px;
			margin-bottom: 120px;
			.time-div{
				.course-content{
					height: 200px;
					p{
						font-size: 20px;
						line-height: 40px;
					}
				}
			}
			.time-content{
				display: -webkit-flex; /* Safari */
				display: flex;
				flex-direction:row;
				justify-content:center;
				.time-content-l{
					width: 600px;
					.l-div1{
						display: -webkit-flex; /* Safari */
						display: flex;
						flex-direction:row;
						height: 280px;
						.l-div1-l{
							width: 420px;
							height: 140px;
							padding-right: 20px;
							.time-text{
								display: inline-block;
								padding-top: 20px;
								font-size: 26px;
								font-weight: 600;
								color: #217bfb;
							}
							.content{
								display: inline-block;
								padding-top: 10px;
								font-size: 20px;
								color: #555555;
								line-height: 35px;
							}
						}
						.l-div1-r{
							width: 220px;
							height: 40px;
							border-bottom: 2px solid #217bfb;
						}
					}
				}
				.time-content-r{
					border-left: 2px solid #217bfb;
					width: 598px;
					.r-div1{
						display: -webkit-flex; /* Safari */
						display: flex;
						flex-direction:row;
						height: 280px;
						.r-div1-l{
							width: 220px;
							height: 40px;
							border-bottom: 2px solid #217bfb;
						}
						.r-div1-r{
							padding-left: 37px;
							width: 420px;
							height: 140px;
							.time-text{
								display: inline-block;
								padding-top: 20px;
								font-size: 26px;
								font-weight: 600;
								color: #217bfb;
							}
							.content{
								display: inline-block;
								padding-top: 10px;
								font-size: 20px;
								color: #555555;
								line-height: 35px;
							}
						}
					}
				}
			}
		}
	}
</style>
